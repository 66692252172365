import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ApplianceDetailsStore, ApplianceDetailsState } from './appliance-details.store';
import { Observable } from 'rxjs';
import { IdName } from '@shared/models/id-name';
import { TranslateService } from '@ngx-translate/core';
import { APPLIANCE_TYPES } from '../models/appliance-type.constants';

@Injectable({ providedIn: 'root' })
export class ApplianceDetailsQuery extends Query<ApplianceDetailsState> {
	isUpperChecked$: Observable<boolean> = this.select(state => state.isUpperChecked);
	isLowerChecked$: Observable<boolean> = this.select(state => state.isLowerChecked);
	applianceType$: Observable<IdName> = this.select(state => state.applianceType);
	applianceTypes$: Observable<IdName[]> = new Observable(observer => {
		const translatedTypes = APPLIANCE_TYPES.map(type => ({
			...type,
			Name: this.translateService.instant(`ApplianceDetails.${type.Name}Type`)
		}));

		observer.next(translatedTypes);
		observer.complete();
	});

	get applianceType(): IdName {
		return this.getValue().applianceType;
	}

	constructor(
		protected store: ApplianceDetailsStore,
		private translateService: TranslateService
	) {
		super(store);
	}
}
