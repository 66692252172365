import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { GeneralRxDataModule } from '@modules/general-rx-data/general-rx-data.module';
import { PrintRxComponent } from './containers/print-rx/print-rx.component';
import { TeethDiagramModule } from '@modules/teeth-diagram/teeth-diagram.module';
import { TreatmentInformationModule } from '@modules/treatment-information/treatment-information.module';
import { NotesModule } from '@modules/notes/notes.module';
import { ScanOptionsModule } from '@modules/scan-options/scan-options.module';
import { DentureDetailsInfoComponent } from '@modules/denture-details';
import { ApplianceDetailsComponent } from '@modules/appliance-details/containers/appliance-details/appliance-details.component';
import { ApplianceDetailsInfoComponent } from '../appliance-details/containers/appliance-details-info/appliance-details-info.component';

@NgModule({
	declarations: [PrintRxComponent],
	exports: [PrintRxComponent],
	imports: [
		CommonModule,
		SharedModule,
		GeneralRxDataModule,
		TeethDiagramModule,
		TreatmentInformationModule,
		NotesModule,
		DentureDetailsInfoComponent,
		ApplianceDetailsComponent,
		ScanOptionsModule,
		ApplianceDetailsInfoComponent
	]
})
export class PrintRxModule {}
