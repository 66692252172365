import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom, mapTo, audit } from 'rxjs/operators';
import { PatientQuery } from '../state/patient-query';
import { combineQueries } from '@datorama/akita';
import { DateOfBirthUtils } from '@shared/utils/date-of-birth-utils';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable({
	providedIn: 'root'
})
export class PatientAppUrlBuilderService {
	private apiEndpointDependingOnHost$ = this.shellQuery.apiEndpoint$.pipe(
		withLatestFrom(this.shellQuery.patientAppApiEndpoint$, this.hostPlatformService.isScanner$),
		map(([apiEndpoint, patientAppApiEndpoint, isHostPlatformScanner]) => {
			let finalApiEndpoint = patientAppApiEndpoint ?? apiEndpoint;
			return isHostPlatformScanner ? finalApiEndpoint.replace('RxEditor', '') : `${finalApiEndpoint}/mvc`})
	);

	constructor(private shellQuery: ShellQuery, private patientQuery: PatientQuery, private hostPlatformService: HostPlatformService) {}

	getInlinePatientAppUrl(): Observable<string> {
		const allConfigLoaded = combineQueries([this.shellQuery.userSettings$, this.shellQuery.rxConfiguration$]).pipe(
			filter(([userSettings, rxConfiguration]) => !!(userSettings && rxConfiguration)),
			mapTo(true)
		);

		return combineQueries([
			this.shellQuery.patientAppUrl$,
			this.shellQuery.companyId$,
			this.shellQuery.contactId$,
			this.shellQuery.dateFormat$,
			this.apiEndpointDependingOnHost$,
			this.shellQuery.languageCode$,
			this.shellQuery.regulatoryDOBMask$
		]).pipe(
			audit(() => allConfigLoaded),
			filter(([patientAppUrl]) => !!patientAppUrl),
			map(([patientAppUrl, companyId, contactId, dateFormat, patientAppApiEndpoint, languageCode, dateOfBirthMask]) => {
				const params = {
					baseUrl: patientAppUrl,
					companyId,
					contactId,
					dateOfBirthFormat: DateOfBirthUtils.getFormat(dateFormat, dateOfBirthMask),
					patientAppApiEndpoint,
					languageCode
				};

				return this.buildInlineUrl(params);
			})
		);
	}

	getEditPatientAppUrl(): Observable<string> {
		return combineQueries([
			this.shellQuery.patientAppUrl$,
			this.shellQuery.companyId$,
			this.apiEndpointDependingOnHost$,
			this.shellQuery.languageCode$,
			this.hostPlatformService.isScanner$,
			this.shellQuery.dateFormat$,
			this.shellQuery.regulatoryDOBMask$
		]).pipe(
			filter(([patientAppUrl]) => !!patientAppUrl),
			withLatestFrom(this.patientQuery.patient$),
			map(([[patientAppUrl, companyId, patientAppApiEndpoint, languageCode, isScanner, dateFormat, dateOfBirthMask], patient]) =>
				this.buildEditUrl({
					baseUrl: patientAppUrl,
					companyId,
					patientAppApiEndpoint,
					languageCode,
					patientUid: patient?.UID,
					patientType: patient?.Type,
					isMidcSource: !isScanner,
					dateOfBirthFormat: DateOfBirthUtils.getFormat(dateFormat, dateOfBirthMask)
				})
			)
		);
	}

	getSearchPatientAppUrl(): Observable<string> {
		return combineQueries([
			this.shellQuery.patientAppUrl$,
			this.shellQuery.companyId$,
			this.apiEndpointDependingOnHost$,
			this.shellQuery.languageCode$,
			this.shellQuery.dateFormat$,
			this.shellQuery.regulatoryDOBMask$
		]).pipe(
			filter(([patientAppUrl]) => !!patientAppUrl),
			map(([patientAppUrl, companyId, patientAppApiEndpoint, languageCode, dateFormat, dateOfBirthMask]) =>
				this.buildSearchUrl({
					baseUrl: patientAppUrl,
					companyId,
					patientAppApiEndpoint,
					languageCode,
					dateOfBirthFormat: DateOfBirthUtils.getFormat(dateFormat, dateOfBirthMask)
				})
			)
		);
	}

	private buildInlineUrl({
		baseUrl,
		companyId,
		contactId,
		patientAppApiEndpoint,
		dateOfBirthFormat,
		languageCode
	}: {
		baseUrl: string;
		companyId: number;
		contactId: number;
		patientAppApiEndpoint: string;
		dateOfBirthFormat: string;
		languageCode: string;
	}): string {
		const queryString = new URLSearchParams({
			isInlineMode: `${true}`,
			companyid: `${companyId}`,
			doctorid: `${contactId}`,
			endpoint: `${patientAppApiEndpoint}`,
			internalEventId: `${28}`,
			dateOfBirthFormat: `${dateOfBirthFormat}`,
			'lang-Code': `${languageCode}`
		});
		return `${baseUrl}?${queryString}`;
	}

	private buildEditUrl({
		baseUrl,
		patientUid,
		companyId,
		patientType,
		patientAppApiEndpoint,
		languageCode,
		isMidcSource,
		dateOfBirthFormat
	}: {
		baseUrl: string;
		patientUid: string;
		companyId: number;
		patientType: number;
		patientAppApiEndpoint: string;
		languageCode: string;
		isMidcSource: boolean;
		dateOfBirthFormat: string;
	}): string {
		const queryString = new URLSearchParams({
			id: patientUid,
			companyid: `${companyId}`,
			type: `${patientType}`,
			endpoint: `${patientAppApiEndpoint}`,
			internalEventId: `${28}`,
			isMidcSource: `${isMidcSource}`,
			'lang-Code': `${languageCode}`,
			dateOfBirthFormat: `${dateOfBirthFormat}`
		});
		return `${baseUrl}/patient?${queryString}`;
	}

	private buildSearchUrl({
		baseUrl,
		companyId,
		patientAppApiEndpoint,
		languageCode,
		dateOfBirthFormat
	}: {
		baseUrl: string;
		companyId: number;
		patientAppApiEndpoint: string;
		languageCode: string;
		dateOfBirthFormat: string;
	}): string {
		const queryString = new URLSearchParams({
			companyid: `${companyId}`,
			endpoint: `${patientAppApiEndpoint}`,
			'lang-Code': `${languageCode}`,
			dateOfBirthFormat: `${dateOfBirthFormat}`
		});
		return `${baseUrl}/patient-search?${queryString}`;
	}
}
