import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { ApplianceDetailsFacade } from '@modules/appliance-details/appliance-details.facade';
import { takeUntil } from 'rxjs/operators';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import * as flex from '@shared/utils/flex-utils';
import { MapperPipe } from '../../../../shared/pipes/mapper.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { DoubleHyphenPipe } from '@modules/general-rx-data/pipes/double-hyphen.pipe';

@Component({
	selector: 'rx-appliance-details-info',
	templateUrl: './appliance-details-info.component.html',
	styleUrls: ['appliance-details-info.component.scss'],
	providers: [ApplianceDetailsFacade],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FlexModule, ExtendedModule, TranslateModule, MapperPipe, DoubleHyphenPipe]
})
export class ApplianceDetailsInfoComponent extends BaseDestroyableComponent implements OnInit {
	getResponsiveDirection = flex.getResponsiveDirection;

	@Input() rx: RxModel;
	@Input() isResponsive = true;

	isUpperChecked: boolean;
	isLowerChecked: boolean;
	applianceType: string;

	constructor(private facade: ApplianceDetailsFacade) {
		super();
	}

	ngOnInit() {
		this.setupApplianceDetails();
	}

	setupApplianceDetails(): void {
		this.isUpperChecked = this.rx?.ApplianceDetails?.UpperJaw;
		this.isLowerChecked = this.rx?.ApplianceDetails?.LowerJaw;

		this.facade.applianceTypes$.pipe(takeUntil(this.componentAlive$)).subscribe(applianceTypes => {
			const applianceType = applianceTypes.find(type => type.Id === this.rx?.ApplianceDetails?.ApplianceType);

			if (applianceType) {
				this.applianceType = applianceType.Name;
			}
		});
	}

	getSrcForRadioButtonImg = (checked: boolean): string => this.facade.getSrcForRadioButtonImg(checked);
}
