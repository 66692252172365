<div class="rectangle-container appliance-details-info">
    <div fxLayout="column">
        <div class="appliance-details-info__title">
            {{ 'ApplianceDetails.ApplianceDetailsTitle' | translate }}
        </div>
        <div fxLayout="row" [fxLayout.lt-sm]="isResponsive | mapper : getResponsiveDirection" class="general-info-body">
            <div fxLayout="column" fxFlex class="info-box">
                <div class="fields-container appliance-details-info__selectors-container" fxLayout="column">
                    <div class="field">
                        <div class="field-name" fxFlex fxLayout="row" fxLayoutAlign="start center" id="appliance-print-upperAppliance">
                            {{ 'ApplianceDetails.UpperArch' | translate }}:
                            <img class="appliance-details-info__selector"
                                [src]="isUpperChecked | mapper : getSrcForRadioButtonImg" alt="checked">
                        </div>
                    </div>
                    <div class="field">
                        <div class="field-name" fxFlex fxLayout="row" fxLayoutAlign="start center" id="appliance-print-lowerAppliance">
                            {{ 'ApplianceDetails.LowerArch' | translate }}:
                            <img class="appliance-details-info__selector"
                                [src]="isLowerChecked | mapper : getSrcForRadioButtonImg" alt="checked">
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass.lt-sm]="{'horizontal' : isResponsive}" class="rx-separator vertical"></div>
            <div fxLayout="column" fxFlex class="info-box">
                <div class="fields-container" fxLayout="column">
                    <div class="field" id="appliance-print-appliancetype">
                        <div class="field-name">
                            {{ 'ApplianceDetails.Type' | translate }}:
                        </div>
                        <div class="field-info">{{applianceType | doubleHyphen }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
