<div class="print-container" *ngIf="rxConfiguration$ | async" [class.landscape]="orientation === printOrientation.landscape" >
    <div *ngFor="let rx of rxs" class ="rx-container">
        <div>
            <div class="rx-header-container" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex fxLayout="row grow" fxLayoutAlign="start center" class="header-position">
                    <div class="title-order-id">
                        <span class="title-text">{{ 'PrintRx.Order' | translate }}:</span>
                        <span class="title-prop-description">{{ rx.rxModel.Order?.ID || '--' }}</span>
                    </div>
                    <hr class="title-separator" />
                    <div *ngIf="rx.rxModel.Patient?.ChartNumber" class="title-chart-number" fxFlex="50%">
                        <span class="title-text">{{ 'GeneralRxData.ChartNumber' | translate }}:</span>
                        <span class="title-prop-description">{{ rx.rxModel.Patient?.ChartNumber || '--' }}</span>
                    </div>
                    <div class="title-patient-fullname" fxFlex="initial">
                        <span class="title-text">{{ 'PatientSection.Patient' | translate }}:</span>
                        <span class="title-prop-description ">{{ rx.rxModel.Patient?.FullName || '--' }}</span>
                    </div>
                </div>

                <div class="logo-container">
                    <img [src]="logoSrc" alt="iTero-logo-missing" (load)="onLogoLoaded( )" />
                </div>
            </div>
            <rx-general-print [rxForPrint]="rx.rxModel"></rx-general-print>
            <rx-denture-details-info [rx]="rx.rxModel" [isResponsive]="false" *ngIf="rx.rxModel.Order.ProcedureId | mapper : isDentureDetailsVisible"></rx-denture-details-info>
            <rx-appliance-details-info [rx]="rx.rxModel" [isResponsive]="false" *ngIf="isApplianceSectionVisible(rx.rxModel)"></rx-appliance-details-info>
			<rx-scan-options-print *ngIf="rx.rxModel | mapper: isAnyScanOptionPresented" [rx]="rx.rxModel"></rx-scan-options-print>
            <div *ngIf="rx.isRestorativeOrLegacyCheck">
                <rx-teeth-diagram
                    [isProcedureFlow]="rx?.rxModel?.Version | mapper : checkIsProcedureFlow"
                    (imagesLoaded)="onImagesLoaded()"
                    [rxForPrint]="rx.rxModel"
                ></rx-teeth-diagram>
            </div>
            <div>
                <rx-treatment-information
                    [isProcedureFlow]="rx?.rxModel?.Version | mapper : checkIsProcedureFlow"
                    [isTreatmentsTableShownEmpty]="true"
                    [rxForPrint]="{ rx: rx.rxModel, componentPart: rx.treatmentInformationComponent }"
                ></rx-treatment-information>
            </div>

            <div>
                <rx-notes [rxForPrint]="rx.rxModel"></rx-notes>
            </div>
        </div>
    </div>
</div>
