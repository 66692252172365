<div class="rx-card">
	<div class="rx-card-title">
		<div class="rx-title-row" fxLayout="row" fxLayoutAlign="start center">
			<div>{{ 'ApplianceDetails.ApplianceDetailsTitle' | translate }}:</div>
		</div>
	</div>

	<form class="rx-card-body" [formGroup]="form" fxLayout="row" fxLayoutAlign="start stretch">
		<!-- Arch Title Row -->
		<div class="rx-title-row arch-title " fxLayout="column" fxLayoutAlign="start stretch" fxFlex="100%">
			<div class="rx-title-row arch-title smallTitle" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="100%">
				<small>{{ 'ApplianceDetails.Arch' | translate }}</small>
			</div>

			<!-- Selectors Row -->
			<div class="selectors-row" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="100%">
				<!-- Upper and Lower Arch Toggle -->
				<div fxLayout="row" fxLayoutAlign="start center" fxFlex="50%">
					<label class="toggle-label">{{ 'ApplianceDetails.UpperArch' | translate }}</label>
					<mat-slide-toggle
						class="toggle"
						color="primary"
						[formControl]="isUpperControl"
						name="isUpperControl"
						[id]="'isUpper'"
						labelPosition="before"
						[rxIsDisabled]="(isApplianceUpperReadonly$ | async) || (isReadonly$ | async)"
					>
					</mat-slide-toggle>

					<label class="toggle-label">{{ 'ApplianceDetails.LowerArch' | translate }}</label>
					<mat-slide-toggle
						class="toggle"
						color="primary"
						[formControl]="isLowerControl"
						name="isLowerControl"
						[id]="'isLower'"
						labelPosition="before"
						[rxIsDisabled]="(isApplianceLowerReadonly$ | async) || (isReadonly$ | async)"
					>
					</mat-slide-toggle>
				</div>
			</div>
		</div>
		<!-- Arch Title Row -->
		<div
			*ngIf="isApplianceTypeVisible$ | async"
			class="rx-title-row arch-title"
			fxLayout="column"
			fxLayoutAlign="start stretch"
			fxFlex="100%"
		>
			<!-- Appliance Type Selector (Conditional) -->
			<div fxLayout="column" class="sides" fxLayoutAlign="stretch stretch">
				<rx-object-selector
					[id]="'applianceType'"
					[label]="'ApplianceDetails.Type' | translate"
					[formControl]="applianceTypeControl"
					[options]="applianceTypes$ | async"
					[isReadOnly]="isReadonly$ | async"
					[isCancellable]="true"
					name="applianceTypeControl"
				>
				</rx-object-selector>
			</div>
		</div>
	</form>
</div>
