import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AwarenessService } from '@modules/awareness/services/awareness.service';
import { RxForDoctorFacade } from '@modules/rx-for-doctor/rx-for-doctor.facade';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { RxAttachmentsVisibiltyEnum } from '@shared/models/enums/rx-attachments-visiblity.enum';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { RxAttachmentsFacade } from '@shared/rx-attachments/rx-attachments.facade';
import { ShellStore } from '@shared/store/shell/shell-store';
import { Observable, combineLatest } from 'rxjs';
import { filter, shareReplay, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PdfCreationService } from '@shared/services/pdf-creation.service';
import { BiSessionInfo } from '@itero/rx-attachments/lib/models/analytics/BiSessionInfo';
import { ProcedureMap } from '@shared/models/procedure-map';
import { WINDOW } from '@shared/tokens';

@Component({
	selector: 'rx-for-doctor',
	templateUrl: './rx-for-doctor.component.html',
	styleUrls: ['./rx-for-doctor.component.scss'],
	providers: [RxForDoctorFacade, RxAttachmentsFacade]
})
export class RxForDoctorComponent extends BaseDestroyableComponent implements OnInit, OnDestroy {
	rxConfiguration$: Observable<RxConfiguration> = this.facade.rxConfiguration$;
	isChairSideMillingAvailableForDownload$: Observable<boolean> = this.facade.isChairSideMillingAvailableForDownload$;
	companyCountryCode$: Observable<string> = this.facade.companyCountryCode$;
	companyId$: Observable<number> = this.facade.companyId$;
	isScanOptionsVisible$: Observable<boolean> = this.facade.isScanOptionsVisible$;
	isTeethDiagramAreaVisible$: Observable<boolean> = this.facade.isTeethDiagramAreaVisible$;
	isPdfButtonVisible$: Observable<boolean> = this.facade.isDownloadPdfButtonVisible();
	isPdfButtonDisabled$: Observable<boolean> = this.facade.isRxValidForSave$.pipe(map(isRxValid => !isRxValid));
	isProcedureFlow$: Observable<boolean> = this.facade.isProcedureFlow$;
	procedureMap$: Observable<ProcedureMap> = this.facade.procedureMap$;
	isDentureDetailsVisible$: Observable<boolean> = this.facade.isDentureDetailsVisible$;
	isApplianceDetailsVisible$: Observable<boolean> = this.facade.isApplianceDetailsVisible$;
	isMultiBiteVisible$: Observable<boolean> = this.facade.isMultiBiteVisible$;
	rxAttachmentsSectionVisibility$: Observable<RxAttachmentsVisibiltyEnum> = this.rxAttachmentsFacade.rxAttachmentsSectionVisibility$;
	attachmentsRxId$: Observable<string> = this.facade.attachmentsRxId$;
	patientGuid$: Observable<string> = this.facade.patientGuid$;
	isDraftSelected$: Observable<boolean> = this.rxAttachmentsFacade.isDraftSelected$;
	shouldShowIsDraftMessage$: Observable<boolean> = this.rxAttachmentsFacade.shouldShowIsDraftMessage$;
	noticeConfig: { companyCountryCode: string; noticeTranslationPath: string }[] = this.facade.noticeComponentConfig;
	isPatientAppReady$: Observable<boolean> = this.facade.isPatientAppReady$;
	isPatientReadyAndLoaded$: Observable<boolean> = this.facade.isPatientReadyAndLoaded$;
	isReadOnlyBannerVisible$ = this.facade.isReadOnlyBannerVisible$;
	featureToggles$ = this.facade.featureToggles$;
	companySoftwareOptions$ = this.facade.companySoftwareOptions$;
	isAttachmentsReadOnly$ = this.rxAttachmentsFacade.isAttachmentsReadOnly$;
	showLmrAwareness$ = this.awarenessService.showLmrAwareness$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
	showGaAwareness$ = this.awarenessService.showGaAwareness$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
	isShowEarlyAccess$: Observable<boolean> = this.facade.isShowEarlyAccess$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
	sessionInfo$: Observable<BiSessionInfo> = combineLatest([this.facade.authInfo$, this.facade.appSettings$]).pipe(
		map(([{ sessionId, sessionType }, { biTimberUrl }]) => {
			return {
				sessionId,
				sessionType,
				biTimberUrl
			} as BiSessionInfo;
		})
	);
	contactId$ = this.facade.contactId$;
	userRole$: Observable<any> = this.facade.userRole$;
	timberUrl$: Observable<string> = this.facade.appSettings$.pipe(
		map(({ logging }) => {
			return logging.url;
		}),
		takeUntil(this.componentAlive$)
	);

	constructor(
		private shellStore: ShellStore,
		private facade: RxForDoctorFacade,
		private awarenessService: AwarenessService,
		private rxAttachmentsFacade: RxAttachmentsFacade,
		private pdfCreationService: PdfCreationService,
		@Inject(WINDOW) private window: Window
	) {
		super();
	}
	ngOnInit(): void {
		this.facade.contactId$
			.pipe(
				tap(() => {
					this.initRxForDoctor().subscribe();
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();

		this.subscribeToIsPatientAppReady();
		this.showAwareness();
	}
	onAttachmentsListUpdated(): void {
		const shouldScrollToAttachments = this.window.location.href.includes('#attachments-section');

		if (shouldScrollToAttachments) {
			document.getElementById('attachments-section')?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		}
	}

	ngOnDestroy() {
		this.facade.setPatientAppReadyState(false);
		window.removeEventListener('resize', this.facade.debouncedHandleResize);
		super.ngOnDestroy();
	}

	subscribeToIsPatientAppReady() {
		this.isPatientAppReady$
			.pipe(
				tap(isReady => {
					if (isReady) {
						this.facade.rxComponentsNormalizeScrollPosition();
					}
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	handlePdfButtonClick() {
		this.pdfCreationService.pdfRequested.next(false);
	}

	updateAttachmentLater(checked: boolean): void {
		this.rxAttachmentsFacade.updateAttachmentLater(checked);
	}

	isSafeToCloseUpdated(isSafeToClose: boolean) {
		this.rxAttachmentsFacade.updateRxAttachmentReadyToSaveState(isSafeToClose);
	}

	private showAwareness() {
		this.isPatientReadyAndLoaded$
			.pipe(
				filter(isReady => !!isReady),
				switchMap(() => this.awarenessService.showAwareness()),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private initRxForDoctor(): Observable<unknown> {
		return this.facade.initStoreValues();
	}
}
