import { Injectable } from '@angular/core';
import { combineQueries, Query } from '@datorama/akita';
import { IdName } from '@shared/models/id-name';
import { Procedure } from '@shared/models/procedure';
import { Observable } from 'rxjs';
import { delay, map, shareReplay } from 'rxjs/operators';
import { CaseTypeEnum } from '../models/case-type.enum';
import { CaseTypeOrderForm } from '../models/order-form';
import { OrderFormModel } from '../models/order-form-model';
import { OrderState, OrderStore } from './order-store';
import { ProcedureMap } from '@shared/models/procedure-map';
import { SendToTypeEnum } from '../models/send-to-type.enum';
import { CaseTypeFormStateService } from '../services/case-type-form-state.service';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { TranslateService } from '@ngx-translate/core';
import { ProcedureFormStateService } from '@modules/order/services/procedure-form-state.service';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';

@Injectable({ providedIn: 'root' })
export class OrderQuery extends Query<OrderState> {
	// I use delay(0) because of this problem: https://github.com/datorama/akita/issues/372
	// Don't delete it, or u'll brake the form...
	caseType$: Observable<IdName> = this.select(state => state.caseType).pipe(delay(0));
	treatmentStage$: Observable<IdName> = this.select(state => state.treatmentStage).pipe(delay(0));
	treatmentStages$: Observable<IdName[]> = this.select(state => state.treatmentStages).pipe(
		map(stages =>
			stages.map(stage => ({
				...stage,
				Name: this.translateService.instant(`TreatmentStages.${stage.Name}`)
			}))
		)
	);
	availableProcedureMaps$: Observable<ProcedureMap[]> = this.select(state => state.availableProcedureMaps);
	isMultiBiteSelected$: Observable<boolean> = this.select(state => state.isMultiBiteSelected).pipe(delay(0));
	isDraftSelected$: Observable<boolean> = this.select(state => state.isDraftSelected).pipe(delay(0));
	currentAlignerId$: Observable<string> = this.select(state => state.currentAlignerId).pipe(delay(0));
	sendTo$: Observable<IdName> = this.select(state => state.sendTo).pipe(delay(0));
	availableLabs$: Observable<IdName[]> = this.select(state => state.availableLabs);
	dueDate$: Observable<Date> = this.select(state => state.dueDate).pipe(delay(0));
	minimalAvailableDueDate$: Observable<Date> = this.select(state => state.minimalAvailableDueDate);
	maxDueDate$: Observable<Date> = this.select(state => state.maxDueDate);
	availableCaseTypeIds$: Observable<number[]> = this.select(state => state.availableCaseTypeIds);
	procedureMap$: Observable<ProcedureMap> = this.select(state => state.procedureMap);
	isFinalRecord$: Observable<boolean> = this.select(state => state.isFinalRecord);
	procedure$: Observable<Procedure> = combineQueries([this.shellQuery.procedures$, this.procedureMap$]).pipe(
		map(([procedures, procedureMap]) => procedures.find(p => p.Id === procedureMap?.ProcedureId)),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	dentureUpperSectionIsReadonly$ = this.select(state => state.procedureMap?.DentureUpperSectionIsReadOnly);
	dentureLowerSectionIsReadonly$ = this.select(state => state.procedureMap?.DentureLowerSectionIsReadOnly);

	applianceUpperIsReadonly$ = this.select(state => state.procedureMap?.ApplianceUpperIsReadOnly);
	applianceLowerIsReadonly$ = this.select(state => state.procedureMap?.ApplianceLowerIsReadOnly);

	caseTypeOrderForm$: Observable<CaseTypeOrderForm> = combineQueries([
		this.caseType$,
		this.sendTo$,
		this.isMultiBiteSelected$,
		this.treatmentStage$,
		this.dueDate$,
		this.currentAlignerId$
	]).pipe(
		delay(0),
		map(
			([caseType, sendTo, isMultiBiteSelected, treatmentStage, dueDate, currentAlignerId]) =>
				({ caseType, sendTo, isMultiBiteSelected, treatmentStage, dueDate, currentAlignerId }) as CaseTypeOrderForm
		)
	);

	get treatmentStage(): IdName {
		return this.getValue().treatmentStage;
	}
	get isMultiBiteSelected(): boolean {
		return this.getValue().isMultiBiteSelected;
	}
	get isDraftSelected(): boolean {
		return this.getValue().isDraftSelected;
	}
	get directToLab(): boolean {
		return this.getValue().directToLab;
	}
	get currentAlignerId(): string {
		return this.getValue().currentAlignerId;
	}
	get sendTo(): IdName {
		return this.getValue().sendTo;
	}
	get dueDate(): Date {
		return this.getValue().dueDate;
	}
	get caseType(): IdName {
		return this.getValue().caseType;
	}
	get treatmentStages(): IdName[] {
		return this.getValue().treatmentStages;
	}
	get procedureMap(): ProcedureMap {
		return this.getValue().procedureMap;
	}
	get procedure(): Procedure {
		return this.getProcedure(this.procedureMap);
	}
	get isTreatmentStageVisible(): boolean {
		return this.getValue().isTreatmentStageVisible;
	}
	get availableCaseTypeIds(): number[] {
		return this.getValue().availableCaseTypeIds;
	}
	get availableProcedureMaps(): ProcedureMap[] {
		return this.getValue().availableProcedureMaps;
	}

	get orderModelForSave(): OrderFormModel {
		const state = this.getValue();

		return {
			MultiBiteScan: state.isMultiBiteSelected ?? false,
			TreatmentStage: state.treatmentStage?.Id,
			AlignerNumber: state.currentAlignerId ?? '',
			CaseTypeId: state.caseType?.Id ? state.caseType?.Id : state.procedureMap?.CaseTypeId,
			DueDate: state.dueDate,
			ShipToId: state.sendTo?.Id ?? -1,
			ShipToName: state.sendTo?.Name,
			DirectToLab: state.directToLab,
			ProcedureId: state.procedureMap?.ProcedureId,
			ProcedureMapId: state.procedureMap?.Id,
			ProcedureTypeId: state.procedureMap?.TypeId
		};
	}

	get isInvalidForSendOrderData(): boolean {
		return this.shellQuery.isProcedureFlow ? this.isInvalidForSendProcedureFlowOrderData : this.isInvalidForSendCaseTypeFlowOrderData;
	}

	get isValidForSendOrderData(): boolean {
		return !this.isInvalidForSendOrderData;
	}

	get isInvalidForSendCaseTypeFlowOrderData(): boolean {
		const state = this.getValue();
		const isSendToRequired = this.orderFormControlsStatusService.isSendToRequired({ caseType: state.caseType });
		const isDueDateRequired = this.orderFormControlsStatusService.isDueDateRequired({ caseType: state.caseType });
		const isSendToValid = !isSendToRequired || (isSendToRequired && !!state.sendTo);
		const isDueDateValid = !isDueDateRequired || (isDueDateRequired && !!state.dueDate);

		return !isSendToValid || !isDueDateValid;
	}

	get isInvalidForSendProcedureFlowOrderData(): boolean {
		const { sendTo, dueDate } = this.procedureFormStateService.getFormState(
			this.procedureMap,
			this.shellQuery.isReadOnly,
			this.treatmentStage,
			true, // dont change it to shellQuery.shouldValidateForSend!
			// Because it wrongly marks control "valid to send" during to saving rx!!!
			this.shellQuery.procedureMaps
		);
		const isSendToValid = !sendTo.isRequired || (sendTo.isRequired && !!this.sendTo);
		const isDueDateValid = !dueDate.isRequired || (dueDate.isRequired && !!this.dueDate);

		return !isSendToValid || !isDueDateValid;
	}

	get isGlidewellOrder(): boolean {
		return this.shellQuery.isProcedureFlow
			? this.isGlidewellOrderV1 // V1
			: (this.caseType?.Id ?? this.shellQuery.rx?.Order?.CaseTypeId) === CaseTypeEnum.ChairSideMillingGlidewellIo; // V0
	}

	get isGlidewellOrderV1(): boolean {
		return this.procedureMap?.SendToTypes === SendToTypeEnum.chairsideGlidewell;
	}

	get isDentureOrderV1(): boolean {
		return this.procedure?.Id === ProcedureEnum.Denture_Removable;
	}

	get isApplianceOrder(): boolean {
		return this.procedure?.Id === ProcedureEnum.Appliance;
	}

	constructor(
		protected store: OrderStore,
		private orderFormControlsStatusService: CaseTypeFormStateService,
		private procedureFormStateService: ProcedureFormStateService,
		private shellQuery: ShellQuery,
		private translateService: TranslateService
	) {
		super(store);
	}

	isCurrentCaseType$(currentCaseType: CaseTypeEnum): Observable<boolean> {
		return this.caseType$.pipe(map(caseType => caseType?.Id === currentCaseType));
	}

	private getAllProcedures(): Procedure[] {
		return this.shellQuery.rxConfiguration?.Procedures;
	}

	private getProcedure(procedureMap: ProcedureMap): Procedure {
		return this.getAllProcedures()?.find(p => p.Id === procedureMap?.ProcedureId);
	}
}
