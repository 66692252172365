import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IdName } from '@shared/models/id-name';
import { ApplianceTypeEnums } from '../models/appliance-type.enum';

export interface ApplianceDetailsState {
	isUpperChecked: boolean;
	isLowerChecked: boolean;
	applianceType: IdName;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createInitialState(): ApplianceDetailsState {
	return {
		isUpperChecked: false,
		isLowerChecked: false,
		applianceType: { Id: ApplianceTypeEnums.Empty, Name: '' }
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'applianceDetails', resettable: true })
export class ApplianceDetailsStore extends Store<ApplianceDetailsState> {
	constructor() {
		super(createInitialState());
	}

	resetType(): void {
		const { applianceType } = createInitialState();

		const currentState = this.getValue();

		this.update({
			isUpperChecked: currentState.isUpperChecked,
			isLowerChecked: currentState.isLowerChecked,
			applianceType
		});
	}
}
