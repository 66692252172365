import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, combineLatest, merge, Observable, of, EMPTY } from 'rxjs';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { OrderQuery } from '@modules/order/state/order-query';
import { map, tap, switchMap, delay } from 'rxjs/operators';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { SoftwareOptionsService } from '@shared/services/software-options.service';
import { VersionsService } from '@shared/services/versions.service';
import { LimitedFeatures } from '@shared/models/limited-features';
import { ShellStore } from '@shared/store/shell/shell-store';
import { Platforms, ProductTypes, SoftwareOptions } from '@shared/models/enums/enums';
import { CaseTypeEnum } from '@modules/order/models/case-type.enum';
import { RxForDoctorQuery } from './state/rx-for-doctor-query';
import { PatientAppIframeCommunicationService } from '@modules/patient/services/patient-app-iframe-communication.service';
import { SpinnerService } from '@core/services/spinner.service';
import { patientSectionModeEnum } from '@modules/patient/models/patient-section-visibility.enum';
import { PatientQuery } from '@modules/patient/state/patient-query';
import debounce from 'lodash.debounce';
import { DentureDetailsUtils } from '@modules/denture-details/utils/denture-details-utils';
import { ApplianceDetailsUtils } from '@modules/appliance-details/utils/appliance-details-utils';
import { combineQueries } from '@datorama/akita';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { AuthInfoQuery } from '@shared/store/authInfo/auth-info-query';
import { RoleTypeEnum } from '@shared/models/role-type';
import { ProcedureMap } from '@shared/models/procedure-map';
import { RxAppSuiteBanner } from '@shared/models/rx-models/interfaces/rx-model';
import { AwarenessService } from '@modules/awareness/services/awareness.service';
import { BiAction, BiBannerType, BiLocationObject, BiObjectType } from '@core/services/logger/interfaces/bi.enums';
import { LoggerService } from '@core/services/logger/logger.service';

@Injectable()
export class RxForDoctorFacade {
	rxConfiguration$: Observable<RxConfiguration> = this.shellQuery.rxConfiguration$;
	isChairSideMillingAvailableForDownload$ = new BehaviorSubject<boolean>(false);
	companyCountryCode$: Observable<string> = this.shellQuery.companyCountryCode$;
	companyId$: Observable<number> = this.shellQuery.companyId$;
	isReadOnly$: Observable<boolean> = this.shellQuery.isReadOnly$;
	contactId$: Observable<number> = this.shellQuery.contactId$;
	isReferralWorkflowPractice$: Observable<boolean> = this.rxForDoctorQuery.isReferralWorkflowPractice$;
	isRxValidForSave$: Observable<boolean> = this.shellQuery.isRxValidForSave$;
	isRxSent$: Observable<boolean> = this.shellQuery.isRxSent$;
	linkToTheLearnNowForDesignSuite$: Observable<string> = this.shellQuery.linkToTheLearnNowForDesignSuite$;
	isTeethDiagramAreaVisible$: Observable<boolean> = combineQueries([
		this.shellQuery.isProcedureFlow$,
		this.orderQuery.caseType$,
		this.orderQuery.procedureMap$
	]).pipe(
		map(([isProcedureFlow, caseTypeSelected, procedureMap]) => {
			if (isProcedureFlow && procedureMap) {
				return procedureMap.ToothDiagramIsEnabled;
			}

			return [
				CaseTypeEnum.Restorative,
				CaseTypeEnum.ChairSideMilling,
				CaseTypeEnum.ChairSideMillingGlidewellIo,
				CaseTypeEnum.ChairSideMillingCerec
			].includes(caseTypeSelected?.Id);
		})
	);

	isMultiBiteVisible$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(map(x => x?.MultiBiteIsEnabled));
	procedureMap$: Observable<ProcedureMap> = this.orderQuery.procedureMap$;

	isScanOptionsVisible$: Observable<boolean> = combineQueries([
		this.shellQuery.isScanOptionsVisible$,
		this.orderQuery.procedureMap$
	]).pipe(
		map(
			([isScanOptionsVisible, procedureMap]) =>
				isScanOptionsVisible ||
				!!(procedureMap?.MultiBiteIsEnabled || procedureMap?.DentureCopyScanIsEnabled || procedureMap?.PreTreatmentScanIsEnabled)
		)
	);

	isProcedureFlow$: Observable<boolean> = this.shellQuery.isProcedureFlow$;

	isDentureDetailsVisible$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(
		map(procedureMap => DentureDetailsUtils.isDentureDetailsEnabled(procedureMap?.ProcedureId))
	);

	isApplianceDetailsVisible$: Observable<boolean> = combineLatest([
		this.orderQuery.procedureMap$,
		this.softwareOptionsService.hasCompanySoftwareOption$(SoftwareOptions.EnableApplianceDetails)
	]).pipe(
		map(([procedureMap, hasCompanySwo]) => {
			return hasCompanySwo && ApplianceDetailsUtils.isApplianceDetailsEnabled(procedureMap?.ProcedureId);
		})
	);

	isPatientAppReady$: Observable<boolean> = this.patientQuery.patientSectionMode$.pipe(
		switchMap(mode =>
			mode === patientSectionModeEnum.InlinePatientApp ? this.patientAppIframeCommunicationService.isPatientAppReady$ : of(true)
		)
	);

	patientGuid$: Observable<string> = this.patientQuery.patientGuid$;

	isPatientAppError$: Observable<boolean> = this.patientAppIframeCommunicationService.isPatientAppError$;

	isPatientReadyAndLoaded$: Observable<boolean> = this.setPatientAppLoader().pipe(isPatientLoaded => isPatientLoaded);

	attachmentsRxId$ = this.shellQuery.attachmentsRxId$;
	featureToggles$ = this.shellQuery.featureToggles$;
	companySoftwareOptions$ = this.shellQuery.companySoftwareOptions$;
	authInfo$ = this.authInfoQuery.authInfo$;
	userRole$: Observable<RoleTypeEnum> = this.shellQuery.userRole$;
	appSettings$ = this.shellQuery.appSettings$;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	debouncedHandleResize = debounce<() => void>(this.normalizeScroll.bind(this), 500);

	readonly facadeName = 'RxForDoctorFacade';

	get noticeComponentConfig(): { companyCountryCode: string; noticeTranslationPath: string }[] {
		return [{ companyCountryCode: 'RU', noticeTranslationPath: 'NoticeForRussianCompany' }];
	}

	isReadOnlyBannerVisible$ = combineLatest([
		this.isReadOnly$,
		this.isRxSent$,
		this.hostPlatformService.isScanner$,
		this.orderQuery.isDraftSelected$
	]).pipe(
		map(([isReadOnly, isRxSent, isScanner, isDraftSelected]) => (isScanner ? isReadOnly : isReadOnly && isRxSent && !isDraftSelected))
	);

	constructor(
		private patientQuery: PatientQuery,
		private orderQuery: OrderQuery,
		private shellQuery: ShellQuery,
		private shellStore: ShellStore,
		private rxForDoctorQuery: RxForDoctorQuery,
		private softwareOptionsService: SoftwareOptionsService,
		private versionsService: VersionsService,
		private patientAppIframeCommunicationService: PatientAppIframeCommunicationService,
		private spinnerService: SpinnerService,
		private hostPlatformService: HostPlatformService,
		private authInfoQuery: AuthInfoQuery,
		private awarenessService: AwarenessService,
		private loggerService: LoggerService,
	) {}

	initStoreValues(): Observable<any> {
		return merge(
			this.setIsNiriCaptureVisibility(),
			this.setSleeveConfirmationVisibility(),
			this.setPreTreatmentVisibilityForV0(),
			this.setIsChairSideMillingAvailableForDownload()
		);
	}

	setPatientAppReadyState(isReady: boolean) {
		this.patientAppIframeCommunicationService.setPatientAppReadyState(isReady);
	}
	isShowEarlyAccess$: Observable<boolean> = this.awarenessService.showEarlyAccessSWO$.pipe(
		map(
			(bannerSettings: RxAppSuiteBanner) =>
				bannerSettings.enableBannerMiniAppsInRX || bannerSettings.enableInformAccessBannerInRXAndMiniApp
		)
	);
	isDownloadPdfButtonVisible(): Observable<boolean> {
		return combineLatest([this.isReferralWorkflowPractice$, this.shellQuery.isRxSent$]).pipe(
			map(([isReferralWorkflowPractice, isRxSent]) => {
				return !!(isReferralWorkflowPractice && !isRxSent);
			})
		);
	}
	updateRegisterForDesignSuite(companyId: number, selectedValue: string, bannerType: string) {
		if (bannerType === BiBannerType.BannerRegister.valueOf()) {
			this.awarenessService.updateRegisterForDesignSuite(companyId);
		}
		this.loggerService.userActionEvent({
			locationParentObject: BiLocationObject.RxContainer,
			objectName: bannerType,
			action: BiAction.Clicked,
			objectType: BiObjectType.Button,
			selectedValue,
			automationId: ''
		});
	}
	get hostPlatform(): Platforms {
		return this.hostPlatformService.hostPlatform;
	}
	rxComponentsNormalizeScrollPosition() {
		window.addEventListener('resize', this.debouncedHandleResize);
	}

	private normalizeScroll(): void {
		document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
	}

	private setIsNiriCaptureVisibility() {
		return combineLatest([
			this.hostPlatformService.isScanner$,
			this.softwareOptionsService.hasScannerSoftwareOption$(SoftwareOptions.ShouldEnableEvx),
			this.softwareOptionsService.hasScannerSoftwareOption$(SoftwareOptions.NGNIRI),
			this.shellQuery.productType$
		]).pipe(
			map(([isHostPlatformScanner, hasShouldEnableEvxSwo, hasNgNiriSwo, productType]) => {
				return isHostPlatformScanner
					? (productType === ProductTypes.evx && hasShouldEnableEvxSwo) || (productType === ProductTypes.ngPro && hasNgNiriSwo)
					: hasShouldEnableEvxSwo || hasNgNiriSwo;
			}),
			tap(isNiriCaptureVisible => this.shellStore.update({ isNiriCaptureVisible }))
		);
	}

	private setSleeveConfirmationVisibility() {
		return combineLatest([
			this.hostPlatformService.isScanner$,
			this.shellQuery.productType$,
			this.shellQuery.isRxSent$,
			this.shellQuery.clientVersion$,
			this.shellQuery.packageVersion$,
			this.softwareOptionsService.hasScannerSoftwareOption$(SoftwareOptions.ShouldEnableEvx),
			this.shellQuery.isSleeveConfirmed$
		]).pipe(
			map(
				([isHostPlatformScanner, productType, isRxSent, clientVersion, packageVersion, hasShouldEnableEvxSwo, isSleeveConfirmed]: [
					boolean,
					string,
					boolean,
					string,
					string,
					boolean,
					boolean
				]) => {
					if (isHostPlatformScanner) {
						if (isRxSent && isSleeveConfirmed) {
							return true;
						}

						const isSwoEnabled =
							(productType === ProductTypes.evx && hasShouldEnableEvxSwo) ||
							(!!productType && productType !== ProductTypes.evx);

						return (
							isSwoEnabled &&
							this.versionsService.isFeatureAvailableForScanner(
								LimitedFeatures.SleeveConfirmation,
								clientVersion,
								packageVersion
							)
						);
					}

					return false;
				}
			),
			tap(isSleeveConfirmationVisible => this.shellStore.update({ isSleeveConfirmationVisible }))
		);
	}

	private setPreTreatmentVisibilityForV0() {
		return combineLatest([
			this.hostPlatformService.isScanner$,
			this.orderQuery.caseType$,
			this.shellQuery.isRxSent$,
			this.shellQuery.prePrepScan$
		]).pipe(
			map(([isHostPlatformScanner, caseType, isRxSent, isPreTreatment]) => {
				if (isHostPlatformScanner) {
					return (
						(isRxSent && isPreTreatment) ||
						[CaseTypeEnum.Restorative, CaseTypeEnum.ChairSideMilling, CaseTypeEnum.ChairSideMillingGlidewellIo].includes(
							caseType?.Id
						)
					);
				}

				return false;
			}),
			tap(isPreTreatmentVisibleForV0 => this.shellStore.update({ isPreTreatmentVisibleForV0 }))
		);
	}

	private setIsChairSideMillingAvailableForDownload() {
		return combineLatest([
			this.softwareOptionsService.hasScannerSoftwareOptions$([
				SoftwareOptions.ChairSideMillingE4D,
				SoftwareOptions.ChairSideMillingOther,
				SoftwareOptions.ChairSideMillingGlidewell
			]),
			this.orderQuery.isCurrentCaseType$(CaseTypeEnum.ChairSideMilling)
		]).pipe(
			map(([hasSuitableSoftewareOptions, isChairSideMillingCaseType]) => isChairSideMillingCaseType && hasSuitableSoftewareOptions),
			tap(result => this.isChairSideMillingAvailableForDownload$.next(result))
		);
	}

	private setPatientAppLoader() {
		return merge(
			defer(() => {
				this.spinnerService.pin();

				return EMPTY;
			}),
			combineLatest([
				this.isPatientAppError$,
				this.isPatientAppReady$,
				this.shellQuery.isRxPending$,
				this.shellQuery.isLoading$
			]).pipe(
				map(([isError, isReady, isRxPending, isLoading]) => {
					const isLoaded = (isError || isReady) && !isRxPending && !isLoading;

					if (isLoaded) {
						this.spinnerService.unpin();

						return true;
					}

					return false;
				}),
				delay(500)
			)
		);
	}
}
