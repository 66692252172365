import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import template from 'lodash.template';
import { Observable } from 'rxjs';
import { ShellStore } from '@shared/store/shell/shell-store';
import { ShellQuery } from '@shared/store/shell/shell-query';
import apiMap from '@core/maps/apiMap';
import { HostPlatformService } from '@shared/services/host-platform.service';

const defaultWebOptions = {
	withCredentials: true
};

const defaultScannerOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json; charset=utf-8'
	})
};

export interface IParams {
	[key: string]: any;
}

export interface IApiCall {
	selector: string;
	pathParams?: IParams;
	queryParams?: IParams;
	options?: any;
	showLoader?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(
		private http: HttpClient,
		private shellStore: ShellStore,
		private shellQuery: ShellQuery,
		private hostPlatformService: HostPlatformService
	) {}
	mockPrefix = 'assets/mocks/';
	mockSuffix = '.json';

	/**
	 * @param selector: name of key from API map (apiMap.ts)
	 * @param pathParams: params embedded in path that will be replaced by their key. Looks like this: <%=pathParmaKey%>. see example below
	 * @param queryParams: params that appear following the question mark as <key>=<value>. see example below
	 * @param options: http call options. see interface IApiCall
	 * @param showLoader: predicate on whether the loadeing spinner is visible
	 * Example of request function usage:
	 * apiService.request({ selector: 'getRxConfig', pathParams: { companyId: 11111 }, queryParams:
	 * { isLabOrTechnician: false, LangCode: 'en-us' } })
	 *
	 * in apiMap a url should not include the base url, should include any variable pathParams in a template placeholder <%=pathParmaKey%>.
	 * So an example url would be:
	 * mvc/rx/Configuration/<%=companyId%>
	 *
	 * the queryParams will be compiled onto it and it will look like this:
	 * mvc/rx/Configuration/11111?isLabOrTechnician=false&LangCode=en-us
	 *
	 * Then, the base url is added. For example, the baseUrl is 'http://localhost:54386', and thus the compiled URL will look like this:
	 * http://localhost:54386/mvc/rx/Configuration/11111?isLabOrTechnician=false&LangCode=en-us
	 */
	request = ({ selector, pathParams, queryParams, options = {}, showLoader = true }: IApiCall): Observable<any> => {
		this.shellStore.update({ isLoading: showLoader });
		const { path, pathScanner, mock, method } = apiMap[selector];
		const url = this.hostPlatformService.isScanner ? pathScanner : path;
		const defaultOptions: any = this.hostPlatformService.isScanner ? defaultScannerOptions : defaultWebOptions;
		defaultOptions.headers = this.getHeaders();
		const apiEndpoint = this.shellQuery.apiEndpointFallback;
		const requestURL = apiEndpoint ? this.compileUrl({ url, pathParams, queryParams }) : this.mockUrl(mock);
		return this.http.request(apiEndpoint ? method : 'GET', requestURL, { ...defaultOptions, ...options });
	};

	requestStaticFile = (staticFilePath): Observable<any> => {
		const requestURL = `${this.shellQuery.staticFilesEndpoint}${staticFilePath}`;

		return this.http.request('GET', requestURL, {});
	};

	private compileUrl = ({ url, pathParams, queryParams }: { url: string; pathParams: IParams; queryParams: IParams }): string => {
		const baseUrl = this.shellQuery.apiEndpointFallback;
		const compiledUrl = `${template(url)(pathParams || {})}${this.encodeQueryParams(queryParams)}`;

		return `${baseUrl}/${compiledUrl}`;
	};

	private encodeQueryParams(queryParams: IParams): string {
		if (!queryParams) {
			return '';
		}
		const ret = [];
		for (const paramKey in queryParams) {
			if (paramKey) {
				ret.push(`${encodeURIComponent(paramKey)}=${encodeURIComponent(queryParams[paramKey])}`);
			}
		}
		return `?${ret.join('&')}`;
	}

	private mockUrl(mock: string) {
		return `${this.shellQuery.staticFilesEndpoint}/${this.mockPrefix}${mock}${this.mockSuffix}`;
	}

	private getHeaders(): { [header: string]: string | string[] } {
		const headers: { [header: string]: string | string[] } = {};
		if (this.shellQuery.authToken) {
			headers.token = this.shellQuery.authToken;
		}
		return headers;
	}
}
