import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { ShellStore } from '@shared/store/shell/shell-store';
import { RxComponents } from '@shared/models/rx-models/interfaces/rx-components-model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable({ providedIn: 'root' })
export class ReadOnlyRulesService {
	isTeethDiagramReadOnly$: Observable<boolean> = combineLatest([
		this.shellQuery.getReadOnlyState('teethDiagram'),
		this.hostPlatformService.isIteroLab$
	]).pipe(
		map(([isReadOnly, isHostPlatformIteroLab]) => ReadOnlyRulesService.checkTeethDiagramReadOnly(isReadOnly, isHostPlatformIteroLab))
	);

	get isTeethDiagramReadOnly(): boolean {
		return ReadOnlyRulesService.checkTeethDiagramReadOnly(
			this.shellQuery.getComponentReadOnlyState('teethDiagram'),
			this.hostPlatformService.isIteroLab
		);
	}

	constructor(private shellQuery: ShellQuery, private shellStore: ShellStore, private hostPlatformService: HostPlatformService) {}

	setRules({ readOnlyRules }: { readOnlyRules: RxComponents }) {
		this.shellStore.update({ readOnlyRules });
	}

	update(fieldsToUpdate: Partial<RxComponents>) {
		const newRules = this.shellQuery.readOnlyRules ?? this.setDefaultReadOnlyRules();

		this.shellStore.update({ readOnlyRules: { ...newRules, ...fieldsToUpdate } });
	}

	reset() {
		this.shellStore.update({ readOnlyRules: this.setDefaultReadOnlyRules() });
	}

	isReadOnlyOrHaveReadOnlyRules(): boolean {
		return (
			this.shellQuery.isReadOnly &&
			(!this.shellQuery.readOnlyRules || Object.values(this.shellQuery.readOnlyRules).every(val => val === true))
		);
	}

	private static checkTeethDiagramReadOnly(isReadOnly: boolean, isHostPlatformIteroLab: boolean) {
		return isReadOnly || isHostPlatformIteroLab;
	}

	private setDefaultReadOnlyRules(): RxComponents {
		const isReadOnly = this.shellQuery.isReadOnly;

		return {
			doctor: isReadOnly,
			patient: isReadOnly,
			order: isReadOnly,
			scanOptions: isReadOnly,
			notes: isReadOnly,
			teethDiagram: isReadOnly,
			toothEditor: isReadOnly,
			dentureDetails: isReadOnly,
			applianceDetails: isReadOnly,
			alignNotes: isReadOnly
		};
	}
}
