import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { Observable } from 'rxjs';
import { ApplianceDetailsFacade } from '@modules/appliance-details/appliance-details.facade';
import { IdName } from '@shared/models/id-name';
import { distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { ApplianceDetailsState, createInitialState } from '@modules/appliance-details/state/appliance-details.store';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { IsDisabledDirective } from '@shared/directives/is-disabled/is-disabled.directive';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ObjectSelectorComponent } from '@shared/components/object-selector/object-selector.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
	selector: 'rx-appliance-details',
	templateUrl: './appliance-details.component.html',
	styleUrls: ['./appliance-details.component.scss'],
	providers: [ApplianceDetailsFacade],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FlexModule,
		ReactiveFormsModule,
		ObjectSelectorComponent,
		MatLegacySlideToggleModule,
		MatLegacyFormFieldModule,
		IsDisabledDirective,
		MatDividerModule,
		NgIf,
		AsyncPipe,
		TranslateModule
	]
})
export class ApplianceDetailsComponent extends BaseDestroyableComponent implements OnInit {
	form = this.formBuilder.group<ApplianceDetailsState>(createInitialState());

	applianceType$: Observable<IdName> = this.facade.applianceType$;
	applianceTypes$: Observable<IdName[]> = this.facade.applianceTypes$;
	isReadonly$ = this.facade.isReadonly$;
	isApplianceTypeVisible$: Observable<boolean> = this.facade.isApplianceTypeVisible$;
	isApplianceUpperReadonly$: Observable<boolean> = this.facade.isApplianceUpperReadonly$;
	isApplianceLowerReadonly$: Observable<boolean> = this.facade.isApplianceLowerReadonly$;

	get isUpperControl() {
		return this.form.controls.isUpperChecked;
	}

	get isLowerControl() {
		return this.form.controls.isLowerChecked;
	}

	get applianceTypeControl() {
		return this.form.controls.applianceType;
	}

	constructor(
		private formBuilder: FormBuilder,
		private facade: ApplianceDetailsFacade
	) {
		super();
	}

	ngOnInit(): void {
		this.subscribeToUserChanges();
		this.subscribeToRxLoaded();
		this.subscribeToStoreChanges();
	}

	private subscribeToUserChanges(): void {
		this.form.valueChanges
			.pipe(
				distinctUntilChanged(),
				tap(() => {
					const result = this.facade.updateStore(this.form.getRawValue());

					this.form.patchValue(result.formValues, { emitEvent: false });
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToStoreChanges(): void {
		this.facade
			.subscribeToStoreChanges()
			.pipe(
				distinctUntilChanged(),
				tap(state => {
					this.form.patchValue(state, { emitEvent: false });
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private subscribeToRxLoaded(): void {
		this.facade
			.getLoadedRx()
			.pipe(
				tap(state => this.form.patchValue(state)),
				take(1),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}
}
