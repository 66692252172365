import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { ApplicationModes } from '@shared/models/enums/enums';
import { RxAppId } from '@shared/models/consts';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { LoggerService } from '@core/services/logger/logger.service';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { FeatureToggle } from '@shared/models/feature-toggle';

export interface PendoSdk {
	initializePendoIfRequired(context: PendoContext,
							  errorLogger: (message: string) => void): Promise<void>;
}

export interface PendoContext {

	contactId: number,

	companyId: number,

	appName: string,

	featureFlags: FeatureToggle[],

	companySoftwareOptions: string[],
}

@Injectable({
	providedIn: 'root'
})
export class PendoService {
	private pendoSdk: PendoSdk;

	constructor(
		private logger: LoggerService,
		private shellQuery: ShellQuery
	) {
	}

	initPendo(): Observable<void> {
		return this.initPendoIfNeed();
	}

	/* istanbul ignore next */
	loadScript(src: string): Promise<PendoSdk> {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script');
			script.src = src;
			script.type = 'module';
			script.async = true;
			script.onload = () => {
				import(/* webpackIgnore: true */ src)
					.then(module => resolve(module))
					.catch(error => reject(error));
			};
			script.onerror = () => {
				script?.remove()
				return reject(new Error(`Failed to load script: ${src}`))
			};
			document.head.appendChild(script);
		});
	}

	private initPendoIfNeed(): Observable<void> {
		return this.shellQuery.appSettings$.pipe(
			filter(appSettings => appSettings?.pendoSdkUrl?.length > 0),
			switchMap(appSettings => this.loadScript(appSettings.pendoSdkUrl)),
			tap(pendoSdk => this.pendoSdk = pendoSdk),
			switchMap(() => this.getApplicationModeIfSuitable()),
			switchMap(appMode =>
				combineLatest([this.shellQuery.companySoftwareOptions$,
					this.getLDFeatureFlagsIfScanner(appMode),
					this.shellQuery.appSettings$])),
			switchMap(([companySoftwareOptions, featureToggles, appSettings]) => {
				const pendoAudienceSwos =
					appSettings.pendoAudienceSwos
						?.map(x => (companySoftwareOptions?.some(c => c === x.id) ? x.key : ''))
						?.filter(x => x);
				const context = {
					appName: RxAppId,
					companySoftwareOptions: pendoAudienceSwos,
					companyId: this.shellQuery.companyId,
					featureFlags: featureToggles,
					contactId: this.shellQuery.contactId
				} as PendoContext;

				return this.pendoSdk.initializePendoIfRequired(context,
					(message: string) => this.logger.info(message, { module: 'pendo-service' }));
			}),
			catchError(() => {
				this.logger.error('Pendo initialization error', { module: 'pendo-service' });
				return of();
			})
		)
	}

	private getLDFeatureFlagsIfScanner(appMode: ApplicationModes): Observable<FeatureToggle[]> {
		if (HostPlatformService.isScanner(appMode)) {
			return this.getLDFFs();
		}
		return this.shellQuery.featureToggles$;
	}

	private getLDFFs(): Observable<FeatureToggle[]> {
		const apiEndpoint = this.shellQuery.apiEndpointFallback;
		const ffUrl = `${apiEndpoint.replace(/\/RxEditor$/i, '')}/Web/GetRawFeatureToggle`;
		return from(fetch(ffUrl, { method: 'GET' })).pipe(
			switchMap(response => {
				if (response.ok && response.url === ffUrl) {
					return response.json();
				} else {
					return this.shellQuery.featureToggles$;
				}
			}),
			catchError(() => {
				return this.shellQuery.featureToggles$;
			})
		);
	}

	private getApplicationModeIfSuitable(): Observable<ApplicationModes> {
		return this.shellQuery.applicationMode$.pipe(
			filter(appMode => !!appMode && !HostPlatformService.isOrthoCad(appMode as ApplicationModes)),
			map(appMode => appMode as ApplicationModes)
		);
	}
}
