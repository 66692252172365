import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientModule } from '@modules/patient/patient.module';
import { NotesModule } from '@modules/notes/notes.module';
import { OrderModule } from '@modules/order/order.module';
import { ScanOptionsModule } from '@modules/scan-options/scan-options.module';
import { TeethDiagramModule } from '@modules/teeth-diagram/teeth-diagram.module';
import { RxForDoctorComponent } from './containers/rx-for-doctor/rx-for-doctor.component';
import { SharedModule } from '@shared/shared.module';
import { ToothEditorModule } from '@modules/tooth-editor/tooth-editor.module';
import { NoticeComponent } from './components/notice/notice.component';
import { TreatmentInformationModule } from '@modules/treatment-information/treatment-information.module';
import { AwarenessModule } from '@modules/awareness/awareness.module';
import { RxAttachmentsModule } from '@itero/rx-attachments';
import { DoctorComponent } from '@modules/doctor';
import { DentureDetailsComponent } from '@modules/denture-details';
import { ApplianceDetailsComponent } from '@modules/appliance-details';

@NgModule({
	declarations: [RxForDoctorComponent, NoticeComponent],
	imports: [
		CommonModule,
		DoctorComponent,
		PatientModule,
		OrderModule,
		TeethDiagramModule,
		ToothEditorModule,
		ScanOptionsModule,
		NotesModule,
		TreatmentInformationModule,
		SharedModule,
		DentureDetailsComponent,
		ApplianceDetailsComponent,
		AwarenessModule,
		RxAttachmentsModule
	],
	exports: [RxForDoctorComponent]
})
export class RxForDoctorModule {}
