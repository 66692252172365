import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { ApplianceDetailsState, ApplianceDetailsStore } from '@modules/appliance-details/state/appliance-details.store';
import { ApplianceDetailsQuery } from '@modules/appliance-details/state/appliance-details.query';
import { combineQueries } from '@datorama/akita';
import { OrderQuery } from '@modules/order/state/order-query';
import { IdName } from '@shared/models/id-name';
import { ProcedureMap } from '@shared/models/procedure-map';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { TypeEnum } from '@modules/order/models/type.enum';
import { Observable } from 'rxjs';
import { ApplianceDetailsModel } from '@shared/models/rx-models/interfaces/appliance-details-model';
import { ApplianceTypeEnums } from './models/appliance-type.enum';
@Injectable()
export class ApplianceDetailsFacade {
	applianceTypes$: Observable<IdName[]> = this.query.applianceTypes$;
	applianceType$: Observable<IdName> = this.query.applianceType$;
	isReadonly$: Observable<boolean> = this.shellQuery
		.getReadOnlyState('applianceDetails')
		.pipe(shareReplay({ bufferSize: 1, refCount: true }));
	isApplianceUpperReadonly$: Observable<boolean> = this.orderQuery.applianceUpperIsReadonly$;
	isApplianceLowerReadonly$: Observable<boolean> = this.orderQuery.applianceLowerIsReadonly$;
	applianceUpperValue$: Observable<boolean> = this.query.isUpperChecked$;
	applianceLowerValue$: Observable<boolean> = this.query.isLowerChecked$;
	procedureMap$: Observable<ProcedureMap> = this.orderQuery.procedureMap$;

	isApplianceTypeVisible$: Observable<boolean> = this.orderQuery.procedureMap$.pipe(
		map(procedureMap => {
			return (
				(procedureMap.ProcedureId as ProcedureEnum) === ProcedureEnum.Appliance &&
				((procedureMap.TypeId as TypeEnum) === TypeEnum.Night_guard ||
					(procedureMap.TypeId as TypeEnum) === TypeEnum.Apnea_Sleep_Appliance)
			);
		})
	);

	constructor(
		private store: ApplianceDetailsStore,
		private query: ApplianceDetailsQuery,
		private shellQuery: ShellQuery,
		private orderQuery: OrderQuery
	) {}

	updateStore(formValues: ApplianceDetailsState): { formValues: ApplianceDetailsState } {
		this.store.update({
			isUpperChecked: formValues.isUpperChecked,
			isLowerChecked: formValues.isLowerChecked,
			applianceType: formValues.applianceType ? formValues.applianceType : { Id: ApplianceTypeEnums.Empty, Name: '' }
		});

		return { formValues };
	}

	subscribeToStoreChanges(): Observable<ApplianceDetailsState> {
		return this.query.select().pipe(
			tap(state => {
				return { formValues: state };
			})
		);
	}

	getLoadedRx(): Observable<ApplianceDetailsState> {
		return combineQueries([this.shellQuery.applianceDetails$, this.shellQuery.isProcedureChanged$, this.query.applianceTypes$]).pipe(
			filter(([applianceDetails, isProcedureChanged]) => !!(applianceDetails && !isProcedureChanged)),
			map(([applianceDetails, isProcedureChanged, applianceTypes]: [ApplianceDetailsModel, boolean, IdName[]]) => {
				return {
					isUpperChecked: applianceDetails.UpperJaw,
					isLowerChecked: applianceDetails.LowerJaw,
					applianceType: applianceTypes.find(x => x.Id === applianceDetails.ApplianceType)
				};
			}),
			tap(applianceDetails => {
				this.store.update(applianceDetails);
			})
		);
	}

	getSrcForRadioButtonImg(checked: boolean): string {
		const ICONS_PATH = `${this.shellQuery.staticFilesEndpoint}/assets/icons/`;

		return checked ? `${ICONS_PATH}check.svg` : `${ICONS_PATH}uncheck.svg`;
	}
}
