import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';

export class ApplianceDetailsUtils {
	public static isApplianceDetailsEnabled(procedureId: number): boolean {
		return (procedureId as ProcedureEnum) === ProcedureEnum.Appliance;
	}

	public static isApplianceDetailsEnabledForLab(
		procedureId: number,
		applianceUpperValue: boolean,
		applianceLowerValue: boolean,
		applianceTypeValue: number
	): boolean {
		return (
			(procedureId as ProcedureEnum) === ProcedureEnum.Appliance &&
			(applianceUpperValue || applianceLowerValue || applianceTypeValue !== 0)
		);
	}
}
